export const endpoints = {
  project: {
    list: 'projects?slim=1',
    listWithDetails: 'projects?slim=2',
    create: 'projects',
    get: 'projects/:projectId',
    update: 'projects/:projectId',
    delete: 'projects/:projectId',
    copy: 'projects/:projectId/duplicate',
    shareToken: 'prosumer/projects/:projectId/shareToken',
    shareViaLink: 'prosumer/projects/shareViaLink',
    share: 'prosumer/projects/:projectUuid/share',
    unshare: 'prosumer/projects/:projectUuid/unshare',
  },
  case: {
    list: 'projects/:projectId/cases',
    listWithDetails: 'projects/:projectId/cases?slim=2',
    getListByNodeType: 'projects/:projectId/cases?nodetype=:nodetype&slim=0',
    create: 'projects/:projectId/cases',
    get: 'projects/:projectId/cases/:caseId',
    update: 'projects/:projectId/cases/:caseId',
    delete: 'projects/:projectId/cases/:caseId',
    copy: 'projects/:projectId/cases/:caseId/duplicate',
  },
  scenario: {
    // ids only
    list: 'projects/:projectId/cases/:caseId/scenarios?slim=1',
    // uuid, name, normalizedname
    listNames: 'projects/:projectId/cases/:caseId/scenarios?slim=2',
    listWithDetails: 'projects/:projectId/cases/:caseId/scenarios?slim=3',
    create: 'projects/:projectId/cases/:caseId/scenarios',
    get: 'projects/:projectId/cases/:caseId/scenarios/:scenarioId',
    update: 'projects/:projectId/cases/:caseId/scenarios/:scenarioId',
    forceUpdate:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId?forceDelete=true',
    delete: 'projects/:projectId/cases/:caseId/scenarios/:scenarioId',
    getSigned:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/input_download_url',
    upload:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/input_upload_url?filename=:filename',
    migrate: 'projects/:projectId/cases/:caseId/scenarios/:scenarioId/migrate',
    getXlsxTemplate: 'xlsx_template_url',
    getLogSigned:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/log_download_url',
    getDraftInput:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/get_draft_input',
    getOutputSigned:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/output_download_url',
    getSystemResultSigned:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/system_download_url',
    getResultSigned:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/results_json',
    getMessagesSigned:
      'prosumer/projects/:projectId/cases/:caseId/scenarios/:scenarioId/download_message_url',
    getRescalingSigned:
      'rescaling/:projectId/:caseId/:scenarioId/:simulationId/download_input',
    simulate:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/simulate',
    launch: 'projects/:projectId/cases/:caseId/scenarios/:scenarioId/launch',
    getLoadsCustom:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId',
    getLoadsLib:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId',
    deleteBinary:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId',
    createBinary:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId',
    deleteAllBinary:
      'projects/:projectUuid/cases/:caseUuid/scenarios/:scenarioUuid/binstore',
    binary:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId',
    cloneBinary:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/binstore/:location/:localId/clone',
    latestModifiedScenarios: 'latestModifiedScenarios',
    latestSimulations: 'latest_simulations',
    getDrafts: 'latest_drafts',
    copy: 'projects/:projectUuid/cases/:caseUuid/scenarios/:scenarioUuid/duplicate',
    validate:
      'prosumer/projects/:projectId/cases/:caseId/scenarios/:scenarioId/validate',
    coherenceCheck:
      'prosumer/projects/{project}/cases/{case}/scenarios/{scenario}/validate',
    getImportMessagesSigned:
      'prosumer/projects/:projectId/cases/:caseId/scenarios/:scenarioId/import_errors_url',
    uploadClone:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/import_input_url?filename=:filename',
    startImport:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/start_import_scenario',
    getScenarioVariations:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/variations',
    getScenarioVariation:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/variations/:variationId',
    getScenariosAndVariations: 'projects/:projectId/cases/:caseId/variations',
    latestScenarioVersion: 'scenarios/json-version',
    createDetail:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/details',
    getDetailByDataType:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/details?dataType=:dataType',
    duplicateDetail:
      'projects/{project}/cases/{case}/scenarios/{scenario}/details/{dataType}/{id}/duplicate',
    deleteDetail:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/details/:dataType/:id',
    updateDetail:
      'projects/:projectId/cases/:caseId/scenarios/:scenarioId/details/:dataType/:id',
    singleDetail:
      'projects/{project}/cases/{case}/scenarios/{scenario}/details/{dataType}/{id}',
    details: 'projects/{project}/cases/{case}/scenarios/{scenario}/details',
    subDetails:
      'projects/{project}/cases/{case}/scenarios/{scenario}/details/{dataType}/{detailId}/subResources/{subResource}',
    subDetail:
      'projects/{project}/cases/{case}/scenarios/{scenario}/details/{dataType}/{detailId}/subResources/{subResource}/{id}',
  },
  renewableProfile: {
    list: 'meteomatics/userRequests',
    create: 'meteomatics/submit',
    get: 'meteomatics/:recordId/:uniqueId',
    getSigned: 'renewableprofile/batch/:uniqueId/download',
  },
  notification: {
    list: 'notifications',
    delete: 'notifications/:notificationId',
    read: 'notifications/read',
  },
  library: {
    equipment: 'libraries/equipments/:type',
    topology: 'libraries/topology',
    loads: 'libraries/loads',
    loadsBinary: 'libraries/loads/:libraryId/profile',
  },
  user: {
    getGDPRPolicy: 'user/privacy_notice',
    acceptGDPRPolicy: 'privacy_notice/update',
    getUserByEmail: 'users/filter_email/:email',
    getUserById: 'users/:id',
    userPreferences: 'prosumer/preferences',
  },
  billing: {
    getComputingTime: 'consumption/computingTime',
    getStorage: 'consumption/storage',
  },
  support: {
    support: 'prosumer/support',
  },
  feedback: {
    feedback: 'prosumer/feedback',
  },
  core: {
    latestVersion: 'prosumer/core/latest_version',
  },
  batch: {
    scenario: 'batch/scenarios',
  },
  transversal: {
    generic: 'tdb',
    energyVectors: 'tdb/energy-vectors',
    filters: 'tdb/filters/{evId}',
    prices: 'tdb/prices/{evId}',
  },
};

let _version: any = {};
try {
  _version = (require('./environment-version') || {}).version;
} catch (e) {
  console.warn(
    'No environment-version.ts found! Please run `npm run version:prosumer`',
  );
  _version = {};
}

export const version = _version;
